import { ContainerFluid, Grid, Heading, Image, Link, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import DynamicComponent from '@/bloks/DynamicComponent';
import { Video } from '@/bloks/Other';
import { IntegrationPageStoryblok } from '@/components';
import { HeroMedia, Integration, MediaImage, MediaVideo } from '@/contexts/Integration';
import { useIntegration } from '@/contexts/Integration/IntegrationProvider';
import { useIntegrator } from '@/contexts/Integration/IntegratorProvider';
import { useServerPageConfig } from '@/contexts/config';
import { markdownToRichtext } from 'storyblok-markdown-richtext';
import React from 'react';
import { t } from 'ttag';
import { renderRichText } from '../../RichText';
import ContactInformation from './Components/ContactInformation';
import { CustomerRatings } from './Components/CustomerRatings';
import CustomerReviews from './Components/CustomerReviews';
import { IntegrationCarousel } from './Components/IntegrationCarousel';
import IntegrationHeader from './Components/IntegrationHeader';
import IntegrationPrice from './Components/IntegrationPrice';
import StickyScroll from './Components/StickyScroll';
import TagCloud from './Components/TagCloud';
import UniqueSellingPoints from './Components/UniqueSellingPoints';
import styles from './IntegrationPage.module.scss';
import { IntegratorListView } from './IntegratorListView';

const isVideo = (media: HeroMedia): boolean => {
	return (media as MediaVideo).videoIdentifier !== undefined;
};

const getVideoId = (videoIdentifier: string): string => {
	const match = RegExp(/(?:video\/|videos\/|\/)(\d+)/).exec(videoIdentifier);
	return match ? match[1] : '';
};

interface CarouselImageProps {
	media?: HeroMedia;
	host?: string;
	index?: number;
}

const CarouselItem: React.FC<CarouselImageProps> = ({ media, host, index }) => {
	if (!media) {
		return <div></div>;
	}

	if (isVideo(media)) {
		const video = media as MediaVideo;
		const id = getVideoId(video.videoIdentifier);

		return (
			<div data-order={index}>
				<Video id={id} className={styles.sliderVideo} />
			</div>
		);
	} else {
		const image = media as MediaImage;
		return (
			<div data-order={index}>
				<Image
					src={`${host}${image.imageUrl}`}
					alt={`Bild ${index} för integration`}
					fill={true}
					objectFit="cover"
					className={styles.sliderImage}
				/>
			</div>
		);
	}
};

interface Props {
	blok: IntegrationPageStoryblok;
}

export const IntegrationPage: React.FC<Props> = ({ blok }) => {
	const { integrations, integration } = useIntegration();
	const { integrator } = useIntegrator();

	const { appsHost: host } = useServerPageConfig();
	const { exits } = blok;

	const isIntegratorPage = integrations && integrations.length > 0;

	const summaryTitle = integration?.summaryTitle ?? '';
	const about = markdownToRichtext(integration?.about ?? '');
	const summary = markdownToRichtext(integration?.summary ?? '');
	const howTo = markdownToRichtext(integration?.howTo ?? '');

	const carouselItems: JSX.Element[] =
		integration?.media.heroes?.reduce<JSX.Element[]>((acc: JSX.Element[], media: HeroMedia, index: number) => {
			acc.push(<CarouselItem media={media} host={host} index={index} />);
			return acc;
		}, []) ?? [];

	return (
		<>
			{isIntegratorPage ? (
				<IntegratorListView />
			) : (
				<ContainerFluid>
					<Section className={styles.container} paddingX={{ base: 'lg', lg: '4xl' }} paddingY={{ base: '5xl' }}>
						<Grid className={styles.integrationGrid} columns={{ base: 1, lg: '8fr 4fr' }}>
							<Grid.Item data-testid="single-integration-content">
								<Grid rowGap="xl" columns={{ base: 1, md: 1, lg: 1 }} className={styles.mainContent}>
									<Grid.Item>
										<IntegrationHeader />
									</Grid.Item>
									<Grid.Item>
										<Heading as="h2" title={summaryTitle} />
										<Text as="div" className={styles.summary}>
											{renderRichText(summary)}
										</Text>
									</Grid.Item>
									<Grid.Item>
										<IntegrationCarousel carouselItems={carouselItems} showDots={false} />
									</Grid.Item>
									<Grid.Item>
										<Heading as="h3" size="h4" title={t`Mer information`} />
										<Text as="div">{renderRichText(about)}</Text>
									</Grid.Item>
									<Grid.Item>
										<Heading as="h3" size="h4" title={t`Så fungerar kopplingen med Fortnox`} />
										<Text as="div">{renderRichText(howTo)}</Text>

										{integration?.readMoreUrl && (
											<LinkButton
												href={integration.readMoreUrl}
												variant="text"
											>{`Läs mer på ${integration.contactInformation.companyName}'s hemsida`}</LinkButton>
										)}
									</Grid.Item>
									<Grid.Item>
										<CustomerReviews />
									</Grid.Item>

									<Grid.Item>
										<CustomerRatings />
									</Grid.Item>
								</Grid>
							</Grid.Item>

							<Grid.Item className={styles.sidebar} as="aside">
								<StickyScroll>
									<UniqueSellingPoints />
									<TagCloud />
									<IntegrationPrice />
									<ContactInformation integrator={integrator} integration={integration} />
								</StickyScroll>
							</Grid.Item>
						</Grid>
					</Section>
				</ContainerFluid>
			)}

			{exits?.map((item) => <DynamicComponent key={item._uid} blok={item} />)}
		</>
	);
};
